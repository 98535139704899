import { API, API_ENDPOINTS } from "../api-config";

const OnBoardInitiatorAPI = {
  postOnboardInitiator: async (payload) => {
    try {
      const res = await API.post(API_ENDPOINTS.ONBOARD_INITIATOR, payload);
      return res;
    } catch (err) {
      console.log("postOnboardInitiator err", err.response);
      throw err;
    }
  },
  
  getTemplateByIndustry: async (industry, categories) => {
    try {
      const endpoint = `${
        API_ENDPOINTS.GET_TEMPLATES_BY_CATEGORIES
      }?industry=${industry}&categories=${categories.join(",")}`;
      const res = await API.get(endpoint);
      return res;
    } catch (err) {
      console.log("get template err", err.response);
      throw err;
    }
  },
};

export default OnBoardInitiatorAPI;
