import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Collapse, Divider, message, Spin } from "antd";
import { formName } from "../../pages/OnBoard";
import DisplayPanel from "../mainLayout/DisplayPanel";
import styled from "styled-components";
import { INDUSTRY_CATEGORIES } from "../../Utils/CONSTANTS";
import seafoodFarmedTemplate from "../../assets/files/seafoodFarmedTemplate.xlsx";
import seafoodWildCaughtTemplate from "../../assets/files/seafoodWildCaughtTemplate.xlsx";
import poultryTemplate from "../../assets/files/poultryTemplate.xlsx";
import epcisStandardTemplate from "../../assets/files/epcisStandardTemplate.xlsx";
import pharmaceuticalTemplate from "../../assets/files/pharmaceuticalTemplate.xlsx";
import woodConstruction from "../../assets/files/woodConstruction.xlsx";
import steelConstruction from "../../assets/files/steelConstruction.xlsx";
import concreteConstruction from "../../assets/files/concreteConstruction.xlsx";
import organicTemplate from "../../assets/files/organicTemplate.xls";
import nonOrganicTemplate from "../../assets/files/nonOrganicTemplate.xls";
import { DownloadOutlined } from "@ant-design/icons";
import CustomTree from "../TreeComponent/CustomTree";
import redMeatTemplate from "../../assets/files/redMeatTemplate.xlsx";
import OnBoardInitiatorAPI from "../../apis/services/OnBoardInitiatorAPI";

const TraceabilityForm = (props) => {
  const { form, industryDetails, setTreeValues } = props;
  const productDetails = industryDetails?.product_details;

  const { Panel } = Collapse;
  const [templates, setTemplates] = useState();
  const [uniqueProductDetails, setUniqueProductDetails] = useState();
  const [checkedKeys, setCheckedKeys] = useState(['X-1-0-1']);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const uniqueProduct = getUniqueProductDetails();
    setUniqueProductDetails(uniqueProduct);
    const categories = getCategories(uniqueProduct);
    getTemplates(categories);
  }, []);

  const onCheckedKeysChange = (checkedKeys, index) => {
    setCheckedKeys(checkedKeys);

    setTreeValues((oldValues) => {
      const updatedValues = [...oldValues];
      updatedValues[index] = { [index]: checkedKeys };
      return updatedValues;
    });
  };

  const getTemplates = async (categories) => {
    try {
      setLoading(true);
      const response = await OnBoardInitiatorAPI.getTemplateByIndustry(
        industryDetails.industry,
        categories
      );
      if (response.data.statusCode === "200") {
        setTemplates(response.data.body);
      } else {
        setTemplates([]);
        console.error("Error try to get templates ");
      }
    } finally {
      setLoading(false);
    }
  };

  const getCategories = (jsonData) => {
    const categories = jsonData.map((entry) => entry.category);
    // var categories = ['Red Meat'];
    return categories;
  };

  const getUniqueProductDetails = () => {
    return (
      Array.isArray(productDetails) &&
      productDetails.filter((item, index) => {
        return (
          productDetails.findIndex(
            (item2) => item2.category === item.category
          ) === index
        );
      })
    );
  };

  const renderFile = (category) => {
    switch (category) {
      case INDUSTRY_CATEGORIES.FARMED:
        return seafoodFarmedTemplate;
      case INDUSTRY_CATEGORIES.WILD_CAUGHT:
        return seafoodWildCaughtTemplate;
      case INDUSTRY_CATEGORIES.RED_MEAT:
        return redMeatTemplate;
      case INDUSTRY_CATEGORIES.POULTRY:
        return poultryTemplate;
      case INDUSTRY_CATEGORIES.FRESH_PRODUCE_ORGANIC:
        return organicTemplate;
      case INDUSTRY_CATEGORIES.FRESH_PRODUCE_NON_ORGANIC:
        return nonOrganicTemplate;
      case INDUSTRY_CATEGORIES.PHARMACEUTICAL || INDUSTRY_CATEGORIES.PROSTHESIS:
        return pharmaceuticalTemplate;
      case INDUSTRY_CATEGORIES.WOOD:
        return woodConstruction;
      case INDUSTRY_CATEGORIES.STEEL:
        return steelConstruction;
      case INDUSTRY_CATEGORIES.CONCRETE:
        return concreteConstruction;
      default:
        return epcisStandardTemplate;
    }
  };

  const getTemplateByCategory = (item) => {
    const template = templates.find(
      (template) => template.category === item.category
    );
    if (template) {
      return template.template;
    } else {
      message.error(`there is no ${item.category} template`);
      return [];
    }
  };

  return (
    <Form layout="vertical" form={form} name={formName.traceability}>
      <div className="right-col">
        <div className="paddingLeft">
          <h4 className="text-white step-heading">Traceability Goals</h4>
        </div>
        <div className="pb-5">
          <div className="paddingLeft fixed-height my-scroller">
            <StyledSpin 
              spinning={loading} 
              tip="Loading templates..." 
            >
              <Row gutter={[15, 12]}>
                <Col span={24}>
                  <SubHeading>
                    Critical Tracking Events (CTE) and Key Data Elements (KDE)
                    Requirements {""}
                  </SubHeading>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="industry"
                    label="Industry"
                    rules={[
                      {
                        required: true,
                        message: "Please select Industry",
                      },
                    ]}
                  >
                    <Input placeholder="Industry" required disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <DisplayPanel
                    // fileName={seafoodTemplate}
                    upperText="This template captures all essential  CTE's and KDE's for standard business process in pharmaceutical industry. CTE's are grouped in to modules and sub-modules, including event descriptions, data elements and data types.  Please refer to the below excel file to understand the critical tracking events that each  module and submodule contains."
                    lowerText="And specify the modules and sub-modules you would need to achieve your traceability goals by marking the check boxes. This information is crucial for customizing our solution to align with your business needs and traceability goals."
                    isNotShowDownload={true}
                  />
                </Col>
                <Col span={24}>
                  {Array.isArray(uniqueProductDetails) &&
                    Object.values(uniqueProductDetails).map((item, index) => {
                      return (
                        <Col span={24} key={index}>
                          <Divider />
                          <Row gutter={[15, 12]} className="align-items-baseline">
                            <Col span={12}>
                              <a
                                href={renderFile(item.category)}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div className="d-flex align-items-center gap-2">
                                  <span style={{ color: "white" }}>
                                    {`${index + 1}.`}{" "}
                                  </span>
                                  {`Download ${item.category} Template`}{" "}
                                  <DownloadOutlined />
                                </div>
                              </a>
                            </Col>
                          </Row>
                          <Row gutter={[15, 12]} key={index}>
                            <Collapse
                              defaultActiveKey={["1"]}
                              style={{ marginTop: "10px" }}
                            >
                              <Panel
                                header={<div>{item.category}</div>}
                                key={index}
                              >
                                  {templates && (
                                    <CustomTree
                                      treeData={getTemplateByCategory(item)}
                                      checkedKeys={checkedKeys}
                                      setCheckedKeys={(checkedKeys) =>
                                        onCheckedKeysChange(checkedKeys, index)
                                      }
                                    ></CustomTree>
                                  )}
                              </Panel>
                            </Collapse>
                          </Row>
                        </Col>
                      );
                    })}
                </Col>
              </Row>
            </StyledSpin>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default TraceabilityForm;

const SubHeading = styled.div`
  font-size: 14px;
  color: var(--white-color);
`;

const StyledSpin = styled(Spin)`
  .ant-spin-text {
    color: white !important;
  }
  .ant-spin-dot-item {
    background-color: white !important;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    background: rgba(0, 0, 0, 0.6);
  }
`;