import React, { useEffect, useState } from "react";
import BusinessDetailsForm from "../components/FormOnboard/BusinessDetailsForm";
import ProductDetailsForm from "../components/FormOnboard/ProductDetailsForm";
import SupplyChainPartnersForm from "../components/FormOnboard/SupplyChainPartnersForm";
import UserDetailsForm from "../components/FormOnboard/UserDetailsForm";
import TraceabilityForm from "../components/FormOnboard/TraceabilityForm";
import SubscriptionThankForm from "../components/FormOnboard/SubscriptionThankForm";
import Sidebar from "../components/Sidebar/Sidebar";
import { Form, Button, Row, Col, message } from "antd";
import {
  useResponsive,
  useFileHandler,
  useOnBoardDataHandler,
} from "../Utils/hooks";
import OnBoardInitiatorAPI from "../apis/services/OnBoardInitiatorAPI";

export const formName = {
  businessDetails: "Business Details",
  productDetails: "Product Details",
  traceability: "Traceability Goals",
  supplyChainPartners: "Supply Chain Partners",
  userDetails: "User Details",
  choosePlan: "Choose Plan",
};

const buttonTypes = {
  previous: "Previous",
  next: "Next",
  submit: "Submit",
};

const OnBoard = () => {
  const screens = useResponsive();

  const [form] = Form.useForm();
  const [activeStep, setActiveStep] = useState(0);
  const [treeValues, setTreeValues] = useState([]);

  const [formValues, setFormValues] = useState({
    businessDetails: {},
    productDetails: {},
    supplyChainPartners: {},
    userDetails: {},
    // choosePlan: {},
    traceability: {},
  }); // form values

  useEffect(() => {
    const savedValues = form.getFieldsValue();
    if (savedValues) {
      form.setFieldsValue({
        ...savedValues,
        ...formValues.userDetails
      });
    }
  }, []);

  const [shouldContinue, setShouldContinue] = useState(false);

  const onBoardProcessData = useOnBoardDataHandler();

  const { createCustomer } = useDataHandler();

  const handlePreviousStep = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleBusinessDetailsSubmit = (values) => {
    console.log("handleBusinessDetailsSubmit", values);
    setFormValues((prev) => ({ ...prev, businessDetails: values }));
  };

  const handleProductDetailsSubmit = (values) => {
    console.log("handleProductDetailsSubmit", values);
    setFormValues((prev) => ({
      ...prev,
      productDetails: values,
    }));
  };

  const handleTraceabilitySubmit = (values) => {
    let finalValues = {};
    let traceability_files = [];

    console.log('inputs ',values, treeValues)

    Object.keys(values).forEach((key) => {
      if (key === "industry") {
        finalValues[key] = values[key];
      } else {
        traceability_files.push({
          [key]: values[key],
        });
      }
    });

    finalValues = {
      ...finalValues,
      traceability_files,
      'events_template':treeValues
    };

    console.log("finalValues", finalValues);
    setFormValues((prev) => ({ ...prev, traceability: finalValues }));
  };

  const handleSupplyChainPartnersSubmit = (values) => {
    // console.log("handleSupplyChainPartnersSubmit", values);
    // setFormValues((prev) => ({
    //   ...prev,
    //   supplyChainPartners: {
    //     ...values,
    //   },
    // }));
    console.log("handleSupplyChainPartnersSubmit", values);
    setFormValues((prev) => ({
      ...prev,
      supplyChainPartners: {
        ...values,
        need_supply_chain: values?.need_supply_chain ? "yes" : "no",
      },
    }));
    console.log("formValues");
    console.log(formValues);
  };

  const handleUserDetailSubmit = async (values) => {
    form.setFieldsValue({
      userDetails: values
    });
    
    setFormValues((prev) => ({
      ...prev,
      userDetails: values
    }));

    const { payload, uploadFileList } = onBoardProcessData.processFormValues(
      values,
      formValues
    );

    const response = await createCustomer(payload, uploadFileList);
    setShouldContinue(response);
    return response;
  };

  const formConfig = [
    {
      step: 0,
      name: formName.businessDetails,
      buttons: ["Next"],
      onSubmit: handleBusinessDetailsSubmit,
      renderForm: () => <BusinessDetailsForm form={form} />,
    },
    {
      step: 1,
      name: formName.productDetails,
      buttons: ["Previous", "Next"],
      onSubmit: handleProductDetailsSubmit,
      renderForm: () => <ProductDetailsForm form={form} />,
    },
    {
      step: 2,
      name: formName.traceability,
      buttons: ["Previous", "Next"],
      onSubmit: handleTraceabilitySubmit,
      renderForm: () => (
        <TraceabilityForm
          form={form}
          industryDetails={formValues.productDetails}
          setTreeValues = {setTreeValues}
        />
      ),
    },
    {
      step: 3,
      name: formName.supplyChainPartners,
      buttons: ["Previous", "Next"],
      onSubmit: handleSupplyChainPartnersSubmit,
      renderForm: () => <SupplyChainPartnersForm form={form} />,
    },
    {
      step: 4,
      name: formName.userDetails,
      buttons: ["Previous", "Submit"],
      onSubmit: handleUserDetailSubmit,
      renderForm: () => <UserDetailsForm form={form} />,
    },
    {
      step: 5,
      renderForm: () => <SubscriptionThankForm />,
    },
  ];

  const currentForm = formConfig.find((form) => {
    // compare the current step with the step in the config
    return form.step === activeStep;
  });

  const onFinishForm = async (name, { values, forms }) => {
    console.log("Success:", name, values, forms, currentForm);
    if (currentForm) {
      const result = await currentForm.onSubmit(values);
      if (currentForm.step === 4) {
        if (result) {
          setActiveStep((prevStep) => prevStep + 1);
        }
      } else {
        setActiveStep((prevStep) => prevStep + 1);
      }
    }
  };

  return (
      <Row
        className="paddingTopBottom"
        // gutter={[5, 5]}
        wrap={screens.md ? false : true}
        style={{ gap: "10px" }}
      >
        <Col
          flex={screens.md ? "500px" : "1"}
          style={{
            backgroundColor: "var(--panel-color)",
          }}
        >
          <Sidebar activeStep={activeStep} />
        </Col>
        <Col
          flex={screens.md ? "auto" : "1"}
          style={{
            backgroundColor: "var(--panel-color)",
          }}
        >
          {" "}
          <div className="right_side">
            <Form.Provider onFormFinish={onFinishForm}>
              <div>{currentForm && currentForm.renderForm()}</div>
              {/* // buttons */}
              {currentForm?.buttons && (
                <div className="stepBtn border-top border-dark gap-3">
                  {currentForm?.buttons?.map((btn, index) => (
                    <React.Fragment key={index}>
                      {btn === buttonTypes.previous && (
                        <Button
                          className="backBtn btn btn-primary"
                          onClick={handlePreviousStep}
                        >
                          {btn}
                        </Button>
                      )}
                      {(btn === buttonTypes.next ||
                        btn === buttonTypes.submit) && (
                        <Button
                          className="nextBtn btn btn-primary"
                          htmlType="submit"
                          form={currentForm?.name} // form name
                        >
                          {btn}
                        </Button>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
            </Form.Provider>
          </div>
        </Col>
      </Row>
  );
};

export default OnBoard;

const useDataHandler = () => {
  const fileHandler = useFileHandler();

  const createCustomer = async (payload, uploadFileList) => {
    console.log("createCustomer", payload, uploadFileList);
    if (
      !uploadFileList.imagesList.length > 0 &&
      !uploadFileList.traceabilityFile.length > 0
    ) {
      return message.error("Please upload all required files");
    } else {
      message.loading("Uploading files, Please wait...");
      const promList = [];

      // upload files to s3
      // make a list of file and s3key from uploadFileList
      let fileAndS3KeyList = [];
      uploadFileList.imagesList.length > 0 &&
        fileAndS3KeyList.push(...uploadFileList.imagesList);
      // uploadFileList.supplyChainFile.length > 0 &&
      //   fileAndS3KeyList.push(...uploadFileList.supplyChainFile);
      uploadFileList.traceabilityFile.length > 0 &&
        fileAndS3KeyList.push(...uploadFileList.traceabilityFile);

      const fileAndS3KeyListLength = fileAndS3KeyList?.length;
      for (let i = 0; i < fileAndS3KeyListLength; i++) {
        const { file, s3Key } = fileAndS3KeyList[i];
        const fileProm = fileHandler.uploadFileToS3(file, s3Key);
        promList.push(fileProm);
      }
      const response = await Promise.all(promList)
      .then((res) => {
        // call api to create customer
        console.log("Final Payload", payload);
        message.loading("Uploading data, Please wait...");
        
        return OnBoardInitiatorAPI.postOnboardInitiator(payload)
          .then((res) => {
            console.log("res", res);
            message.success("Customer created successfully");
            return true;
          })
          .catch((err) => {
            console.log("error", err.response.data.error);
            message.error(err.response.data.error);
            return false;
          });
      })
      .catch((err) => {
        console.log("err", err);
        message.error("Failed to upload files, Something went wrong");
        return false;
      });
    
    console.log('************************** ' + response);
    return response;
    
    }
  };

  return {
    createCustomer,
  };
};