import React, { useState, useEffect } from 'react';
import { Tree } from 'antd';

const CustomTree = ({treeData, checkedKeys, setCheckedKeys}) => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [treeDataState, setTreeDataState] = useState(treeData);

  const onCheck = (checkedKeysValue) => {
    console.log('onCheck', checkedKeysValue);
    setCheckedKeys(checkedKeysValue);
  };

  useEffect(() => {
    setCheckedKeys(checkByDefault(treeData))
    console.log('checked ss',checkedKeys)
  }, []);

  const checkByDefault = (payload )=>{
    let resultKeys = [];
    payload.forEach((item) => {
      if (item.children && Array.isArray(item.children)) {
        const childWithMasterData = item.children.find((child) => {
          return (
            child.title &&
            typeof child.title === 'string' &&
            child.title.includes('Establishment master data')
          );
        });
    
        if (childWithMasterData) {
          if (childWithMasterData.children && Array.isArray(childWithMasterData.children)) {
            childWithMasterData.children.forEach(item => {
              resultKeys.push(item.key)
              item.disabled=true
            })
            }
          childWithMasterData.disabled = true
          resultKeys.push(childWithMasterData.key);
        }
      }
    });
    return resultKeys
  }

  const onSelect = (selectedKeysValue, info) => {
    console.log('onSelect', info);
    setSelectedKeys(selectedKeysValue);
  };
  return (
    <Tree
      value
      className="custom-tree" 
      checkable
      onCheck={onCheck}
      checkedKeys={checkedKeys}
      onSelect={onSelect}
      selectedKeys={selectedKeys}
      treeData={treeData}
    />
  );
};

export default CustomTree;