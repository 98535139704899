import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  InputNumber,
  Divider,
  Button,
  Row,
  Col,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import "./Form.scss";
import { formName } from "../../pages/OnBoard";
import { DeleteOutlined } from "@ant-design/icons";
import {
  INDUSTRY_OPTIONS,
  INDUSTRY_BASED_CATEGORY,
  EXPECTED_LIFE_PERIOD,
} from "../../Utils/CONSTANTS";
import ImageUpload from "../UploadFile/ImageUpload";
import CustomValueSelect from "../Input/CustomValueSelect";
import styled from "styled-components";

const ProductDetailsForm = (props) => {
  const { form } = props;
  const [categoryListing, setCategoryListing] = useState([]);

  const defaultProductValues = {
    industry: undefined,
    product_details: {
      category: undefined,
      // type: undefined,
      name: undefined,
      variant: undefined,
      expected_product_life_time: "",
      expected_product_life_period: undefined,
    },
  };

  useEffect(() => {
    // check form has values or not
    const formValues = form.getFieldsValue();
    console.log("formValues", formValues);
    if (formValues.product_details) {
      form.setFieldsValue({
        product_details: [...formValues.product_details],
      });
      // set category listing
      setCategoryListing(INDUSTRY_BASED_CATEGORY[formValues.industry] || []);
    } else {
      form.setFieldsValue({
        product_details: [defaultProductValues.product_details],
      });
    }
  }, [form, defaultProductValues.product_details]);

  return (
    <>
      <Form layout="vertical" form={form} name={formName.productDetails}>
        <div className="right-col">
          <div className="paddingLeft">
            <h4 className="text-white step-heading">Product Details</h4>
          </div>
          <div className="pb-5">
            <div className="paddingLeft fixed-height my-scroller">
              <Row className="g-4" gutter={[15, 12]}>
                <Col span={24}>
                  <Form.Item
                    name="industry"
                    label="Industry"
                    rules={[
                      {
                        required: true,
                        message: "Please select Industry",
                      },
                    ]}
                    getValueFromEvent={(e) => {
                      setCategoryListing(INDUSTRY_BASED_CATEGORY[e] || []);
                      // set all product values to default
                      const formValues = form.getFieldsValue();
                      const imageValue = formValues.product_details[0].image;
                      form.setFieldsValue({
                        product_details: [
                          {
                            ...defaultProductValues.product_details,
                            image: imageValue,
                          },
                        ],
                      });
                      return e;
                    }}
                  >
                    <Select
                      placeholder="Industry"
                      options={
                        Object.values(INDUSTRY_OPTIONS).map((item) => ({
                          label: item,
                          value: item,
                        })) || []
                      }
                      required
                    ></Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.List name="product_details">
                {(fields, { add, remove }) => (
                  <div>
                    {fields.map(({ key, name, ...restField }) => (
                      <div key={key}>
                        <Divider orientation="left" orientationMargin="0">
                          <div
                            style={{
                              width: "200px",
                              textAlign: "left",
                              fontSize: "14px",
                              color: "#fff",
                            }}
                          >
                            Product {name + 1}
                          </div>
                        </Divider>
                        {name > 0 && (
                          <div
                            style={{
                              float: "right",
                              // marginTop: "5px",
                              marginTop: "-41px",
                              marginRight: "5px",
                              zIndex: 1,
                              position: "relative",
                            }}
                          >
                            <DeleteOutlined
                              style={{
                                cursor: "pointer",
                                color: "#c6c6c6",
                                fontSize: "15px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                remove(name);
                              }}
                            />
                          </div>
                        )}
                        <div>
                          <Row className="g-4" gutter={[15, 12]}>
                            <Col span={24}>
                              <ImageUpload
                                formItemProps={{
                                  name: [name, "image"],
                                  label: "Product Image",
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please select Product Image",
                                    },
                                  ],
                                }}
                              />
                            </Col>
                          </Row>
                          <Row className="g-4" key={key} gutter={[15, 12]}>
                            <Col span={12}>
                              <CustomValueSelect
                                initialOptions={categoryListing}
                                formItemProps={{
                                  label: "Category",
                                  name: [name, "category"],
                                  rules: [
                                    {
                                      required: true,
                                      message: "Please Select Product Category",
                                    },
                                  ],
                                }}
                                selectProps={{
                                  placeholder: "Product Category",
                                }}
                                inputPlaceholder="Enter new product category"
                                btnText={"+ Add Category"}
                              />
                            </Col>
                            {/* <Col span={12}>
                              <Form.Item
                                name={[name, "type"]}
                                label="Product Type"
                                {...restField}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Product Type",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Product Type"
                                  required
                                ></Input>
                              </Form.Item>
                            </Col> */}
                            <Col span={12}>
                              <Form.Item
                                name={[name, "name"]}
                                label="Product Name"
                                {...restField}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Product Name",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Product Name"
                                  required
                                ></Input>
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={[name, "variant"]}
                                label="Product Variant"
                                {...restField}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter Product Variant",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Add Product Variant"
                                  required
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={[name, "expected_product_life_time"]}
                                label="Expected Product Life Time"
                                {...restField}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter Expected Product Life Time",
                                  },
                                ]}
                              >
                                <InputNumberDiv
                                  placeholder="Enter Expected Product Life Time"
                                  min={1}
                                  required
                                />
                              </Form.Item>
                            </Col>
                            <Col span={24}>
                              <Form.Item
                                name={[name, "expected_product_life_period"]}
                                label="Expected Product Life Period"
                                {...restField}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter Expected Product Life Period",
                                  },
                                ]}
                              >
                                <Select
                                  options={EXPECTED_LIFE_PERIOD}
                                  placeholder="Enter Expected Product Life Period"
                                  required
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    ))}
                    <div className="mt-3">
                      <StyledButton
                        className="addProcduct button-trans"
                        onClick={(e) => {
                          e.preventDefault();
                          add();
                        }}
                      >
                        <PlusOutlined /> Add More
                      </StyledButton>
                    </div>
                  </div>
                )}
              </Form.List>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ProductDetailsForm;

const SwitchContainer = styled.div`
  .ant-switch-checked {
    background-color: #4096ff;
  }
`;

const StyledButton = styled(Button)`
  &.button-trans:hover {
    background-color: transparent !important;
    color: #4096ff;
    border-color: #4096ff;
  }
`;

const InputNumberDiv = styled(InputNumber)`
  width: 100%;
  background: transparent !important;
  
  .ant-input-number-input {
    height: 40px;
    font-size: 12px !important;
    background: transparent !important;

    &::placeholder {
      color: #8f90a6;
    }
    
    &:focus {
      background: transparent !important;
    }
  }
  
  &.ant-input-number-focused {
    background: transparent !important;
  }
  
  // Asegurarse de que el fondo sea transparente en todos los estados
  &:hover,
  &:focus,
  &:active {
    background: transparent !important;
  }
`;