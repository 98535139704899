const useOnBoardDataHandler = () => {
  const renderS3Key = (userEmail, fileName, type) => {
    let s3Key = "";
    // const initialIds = new Date();
    // var randomIdInSeconds = Math.floor(initialIds.getTime() / 1000);

    s3Key = `${userEmail}/${type}/${fileName}`;

    return s3Key;
  };

  const getS3keyList = (userValues, productDetails) => {
    const { product_details } = productDetails;

    const newUploadImagesList = [];

    if (Array.isArray(product_details)) {
      for (let i = 0; i < product_details.length; i++) {
        const item = product_details[i];
        const { image } = item;
        const file = image[0]; // must be only one file
        newUploadImagesList.push({
          index: i,
          s3Key: renderS3Key(
            userValues?.user_email,
            file?.name,
            "product_images"
          ),
          file,
        });
      }
    }

    return newUploadImagesList;
  };

  const processProductDetails = (userValues, productDetails, imagesList) => {
    const { industry, product_details } = productDetails;

    // create a list of s3Key and file from product_details
    const productImagesList = getS3keyList(userValues, productDetails);
    console.log("productImagesList", productImagesList);

    // from productImagesList match with product_details and add s3Key in the product_details
    const newProductDetails = [];
    if (Array.isArray(product_details)) {
      const productDetailsLength = product_details?.length;
      for (let i = 0; i < productDetailsLength; i++) {
        const productItem = product_details[i];
        const productImageListLength = productImagesList?.length;
        for (let j = 0; j < productImageListLength; j++) {
          const imageItem = productImagesList[j];
          if (i === imageItem.index) {
            newProductDetails.push({
              ...productItem, industry,
              image: imageItem.s3Key,
            });
          }
        }
      }
    }

    imagesList.push(...productImagesList);

    return newProductDetails
  };

  //   const renderSupplyChainPartners = (
  //     userValues,
  //     supplyChainPartners,
  //     partnersList
  //   ) => {
  //     const { supply_chain_file } = supplyChainPartners;

  //     if (!supply_chain_file)
  //       return {
  //         s3Key: "",
  //       };

  //     const file = supply_chain_file[0] || "";

  //     const s3KeyValue = renderS3Key(
  //         userValues?.user_email,
  //         file?.name,
  //         "supply_chain_partners"
  //       ),
  //       supplyChainFileList = {
  //         s3Key: s3KeyValue,
  //         file: file,
  //       };

  //     partnersList.push(supplyChainFileList);

  //     return {
  //       s3Key: s3KeyValue,
  //     };
  //   };

  const renderTraceability = (userValues, traceability, traceabilityList) => {
    console.log(
      "renderTraceability",
      traceability,
      userValues,
      traceabilityList
    );
    const { traceability_files, industry,events_template } = traceability;
    const newTraceabilityFile = [];

    if (!traceability_files) {
      return {
        industry,
        traceability_files: [],
      };
    }

    const traceabilityFile =
      Array.isArray(traceability_files) &&
      traceability_files.map((item) => {
        let innerFile = {};

        for (const key of Object.keys(item)) {
          console.log("key", key, item[key][0]);

          const { name } = item[key][0];

          const s3KeyValue = renderS3Key(
            userValues?.user_email,
            name,
            "traceability_goals"
          );

          console.log("name", name, s3KeyValue, item[key][0]);

          newTraceabilityFile.push({
            [key]: s3KeyValue,
          });

          innerFile = {
            s3Key: s3KeyValue,
            file: item[key][0],
          };
        }

        return innerFile;
      });

    console.log("traceabilityFile", traceabilityFile);

    traceabilityList.push(...traceabilityFile);

    // will return industry, list of traceability_files as (s3Key)
    return {
      industry,
      traceability_files: newTraceabilityFile,
      events_template
    };
  };

  const renderUserDetails = (userValues) => {
    const { user_email, ...rest } = userValues;
    return {
      ...rest,
      email: user_email,
    };
  };

  const removeUnnecessaryData = (inputJson) => {
    const outputJson = JSON.parse(JSON.stringify(inputJson));
    if (outputJson.events_template && outputJson.events_template.length > 0) {
      outputJson.events_template.forEach((event) => {
        Object.keys(event).forEach((prop) => {
          if (Array.isArray(event[prop])) {
            event[prop] = event[prop].filter(str => str.includes("|")).map((str) => {
                return str.split("|")[0];
            });
          }
        });
      });
    }
  
    return outputJson;
  }

  const processFormValues = (userValues, allFormValues) => {
    const uploadFileList = {
      imagesList: [],
      //   supplyChainFile: [],
      traceabilityFile: [],
    };

    const productListingWithFileName = processProductDetails(
      userValues,
      allFormValues?.productDetails,
      uploadFileList?.imagesList
    );
    const traceability = renderTraceability(
      userValues,
      allFormValues?.traceability,
      uploadFileList?.traceabilityFile
    )
    const payload = {
      company_details: allFormValues?.businessDetails,
      product_details: productListingWithFileName,
      supply_chain_partners: allFormValues?.supplyChainPartners,
      //   supply_chain_partners: renderSupplyChainPartners(
      //     userValues,
      //     allFormValues?.supplyChainPartners,
      //     uploadFileList?.supplyChainFile
      //   ),
      traceability_goals: removeUnnecessaryData(traceability),
      user_details: renderUserDetails(userValues),
    };

    console.log("useOnBoardDataHandler", payload, uploadFileList);

    return {
      payload,
      uploadFileList,
    };
  };

  return {
    processFormValues,
  };
};

export default useOnBoardDataHandler;